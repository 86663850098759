import { setupApi } from './base'
import { MoveToRoomDto } from '@poppin/sdk'

export async function listOwnedPoppin() {
  const { poppinApi } = await setupApi()
  const res = await poppinApi.poppinList()
  return res.data
}

export async function getPoppinById(id: number) {
  const { poppinApi } = await setupApi()
  const res = await poppinApi.poppinGetById(id)
  return res.data
}

export async function hatchPoppin(id: number) {
  const { poppinApi } = await setupApi()
  const res = await poppinApi.poppinHatch(id)
  return res.data
}

export async function renamePoppin(id: number, name: string) {
  const { poppinApi } = await setupApi()
  const res = await poppinApi.poppinRename(id, { name })
  return res.data
}

export async function listRooms(id: number) {
  const { userApi } = await setupApi()
  const res = await userApi.userGetRooms(id)
  return res.data
}

export async function poppinMoveToRoom(
  room: MoveToRoomDto,
  ownedPoppinId: number,
) {
  const { poppinApi } = await setupApi()
  const res = await poppinApi.poppinMoveToRoom(ownedPoppinId, room)
  return res.data
}
