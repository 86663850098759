import styled from '@emotion/styled';
import { css } from '@emotion/react';

interface NewsButtonProps {
  src: string;
  href: string;
  style?: React.CSSProperties;
  swayEffect?: boolean;
  swayWidth?: string;
  onClick?: () => void; // Add this line
}

const createCombinedAnimation = (swayWidth: string = '10px') => css`
    @keyframes combinedSwayMove {
        0%, 100% {
            transform: translateX(-${swayWidth}) rotate(-3deg);
        }
        50% {
            transform: translateX(${swayWidth}) rotate(3deg);
        }
    }
    animation: combinedSwayMove 3s infinite;
`;

const ImageLink = styled.a<{ swayEffect?: boolean; swayWidth?: string }>`
    display: inline-block;
    cursor: pointer;
    ${({ swayEffect, swayWidth }) => swayEffect && createCombinedAnimation(swayWidth)}
`;

// Define Image as a styled component that can accept all img attributes
const Image = styled.img`
  width: 100%;
  height: auto;
`;

export function NewsButton({ src, href, style, swayEffect, swayWidth, onClick }: NewsButtonProps) {
  // Modified onClick handler to include event.preventDefault()
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    if (onClick) onClick();
  };


  return (
    <ImageLink href={href} style={style} target="_blank" rel="noopener noreferrer" swayEffect={swayEffect} swayWidth={swayWidth} onClick={handleClick}>
      <Image src={src} alt="" />
    </ImageLink>
  );
};
