import summernews from './images/summer_campaign_banner.png'
import roomnews from './images/roomnews.jpeg'
import open_popotama from './images/open_popotama.gif'
import take_photo from './images/take_photo.gif'
import evolve from './images/evolve.png'
import party from './images/party.gif'

export let SummerCampaign = `
  <image src=${summernews.src} alt="Summer News" style="width: 100%; height: auto;"/>
  <br/>
  <p>
    🍦Summer is here, so let’s enjoy some ice cream!🍦<br/><br/>
    We’re excited to announce our new event where you can cool down and have fun with ice cream-themed “Popbites” and “Poppins”! 🎉<br/><br/>
    Collect these items to evolve your Milk Bear and Scrin! 🍦🐻
  </p>
`

export let RoomCampaign = `
  <p>
    🍦🌟New Feature Alert:Background Customization Update! <br/><br/>
    Now live for users who purchased background changes. Multiple backgrounds? Enjoy dynamic switching each time you open the app!
  </p>
  <image src=${roomnews.src} alt="Room News" style="width: 100%; height: auto;"/>
  
      <div style="text-align: center; margin-top: 20px;">
  <button 
    onclick="window.open('https://z3q65sezbhp.typeform.com/to/pJC55Plg')"
    style="
      background-color: #4CAF50;
      border: none;
      color: white;
      padding: 15px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin: 4px 2px;
      cursor: pointer;
      border-radius: 12px;
      transition: background-color 0.3s ease;
    "
    onmouseover="this.style.backgroundColor='#45a049'"
    onmouseout="this.style.backgroundColor='#4CAF50'"
  >
    Room Purchase
  </button>
  </div>
`

export let PopotamaCampaign = `
  <p>
  🔥 Popotama distribution has begun!<br/><br/>
    Popotama purchase form here:<br/>
    Don't forget to fill in the details!🫡<br/><br/>
  </p>
    <div style="text-align: center; margin-top: 20px;">
  <button 
    onclick="window.open('https://z3q65sezbhp.typeform.com/to/BSGsA54A')"
    style="
      background-color: #4CAF50;
      border: none;
      color: white;
      padding: 15px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin: 4px 2px;
      cursor: pointer;
      border-radius: 12px;
      transition: background-color 0.3s ease;
    "
    onmouseover="this.style.backgroundColor='#45a049'"
    onmouseout="this.style.backgroundColor='#4CAF50'"
  >
    Popotama Purchase
  </button>
  </div>
`

export let GachaPopotamaCampaign = `
  <p>
  🔥 Popotama NFT Collection 🔥<br/><br/>
    Discover the world of Poppin with the Popotama NFT Collection. <br/>
   </p>
     <image src=${party.src} alt="Party Popotama" style="width: 100%; height: auto;"/>
     <p>
    Each Popotama is a special egg that hatches into a Poppin, a creature that evolves based on your meal data. This collection offers a chance to obtain exclusive characters that are not available through regular gameplay. Unlock unique Poppin and enhance your collection in ways you never thought possible.<br/><br/>
  </p>
    <div style="text-align: center; margin-top: 20px;">
  <button 
    onclick="window.open('https://create.party.app/mint/base/0xC0735F8Bd7ff5d285eaaB50c356FFb5ade0AF984')"
    style="
      background-color: #4CAF50;
      border: none;
      color: white;
      padding: 15px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin: 4px 2px;
      cursor: pointer;
      border-radius: 12px;
      transition: background-color 0.3s ease;
    "
    onmouseover="this.style.backgroundColor='#45a049'"
    onmouseout="this.style.backgroundColor='#4CAF50'"
  >
    Mint Popotama 
  </button>
  </div>
`

export let HatchPopotama = ` 
    <style>
        body {
            max-width: 800px;
            margin: 0 auto;
            padding: 20px;
        }
        h1 {
            text-align: center;
        }
        .image-container {
            text-align: center;
            margin: 20px 0;
        }
        .image-container img {
            width: 15%;
            height: auto;
            display: inline-block;
            margin: 0 5px;
        }
        .center-image {
            display: block;
            margin: 20px auto;
            width: 60%;
            height: auto;
        }
    </style>
    <h1>🎉 Welcome to Poppin Land! : Tutorial (1/3)🎉</h1>

    <div class="image-container">
        <img src="https://poppin-public-assets.s3.us-west-1.amazonaws.com/poppins/15/0/dict.png" alt="poppin1">
        <img src="https://poppin-public-assets.s3.us-west-1.amazonaws.com/poppins/35/0/dict.png" alt="poppin2">
        <img src="https://poppin-public-assets.s3.us-west-1.amazonaws.com/poppins/5/0/dict.png" alt="poppin5">
        <img src="https://poppin-public-assets.s3.us-west-1.amazonaws.com/poppins/25/0/dict.png" alt="poppin3">
        <img src="https://poppin-public-assets.s3.us-west-1.amazonaws.com/poppins/45/0/dict.png" alt="poppin4">
    </div>

    <p>
    Poppin is a mysterious creature that evolves based on the food you eat.
    Tap the Popotama egg in your room to hatch it. 
    </p>

    <img src="${open_popotama.src}" alt="Open Popotama" class="center-image">

    <p>
    Unleash the magic—hatch your Popotama now!
    </p>
 `

export let CapturedPopbite = `
    <style>
        body {
            max-width: 800px;
            margin: 0 auto;
            padding: 20px;
        }
        h1 {
            text-align: center;
            margin-bottom: 10px;
        }
        p {
            margin-top: 5px;
            margin-bottom: 10px;
        }
        .image-container {
            text-align: center;
            margin: 10px 0;
        }
        .image-container img {
            width: 15%;
            height: auto;
            display: inline-block;
            margin: 0 5px;
        }
        .center-image {
            display: block;
            height: auto;
            margin: 10px auto;
            width: 50%;
        }
    </style>
    <h1>📸 Take a Photo of Your Meal : Tutorial (2/3) 📸</h1>

    <img src="${take_photo.src}" alt="take photo" class="center-image">
    <p>
    Capture a photo of your meal!
    The Popbites you receive will depend on the dish you photograph. <br/><br/>
    Note: Food may not be recognized if it's wrapped or taken in a dark setting.
    </p>
    <div class="image-container">
        <img src="https://poppin-public-assets.s3.us-west-1.amazonaws.com/popbites/1000.png" alt="popbites1">
        <img src="https://poppin-public-assets.s3.us-west-1.amazonaws.com/popbites/2001.png" alt="popbites2">
        <img src="https://poppin-public-assets.s3.us-west-1.amazonaws.com/popbites/3002.png" alt="popbites3">
        <img src="https://poppin-public-assets.s3.us-west-1.amazonaws.com/popbites/4003.png" alt="popbites4">
        <img src="https://poppin-public-assets.s3.us-west-1.amazonaws.com/popbites/5004.png" alt="popbites5">
    </div>
 `

export let FedPoppin = `
    <style>
        body {
            max-width: 800px;
            margin: 0 auto;
            padding: 20px;
        }
        h1 {
            text-align: center;
            margin-bottom: 10px;
        }
        p {
            margin-top: 5px;
            margin-bottom: 10px;
        }
        .image-container {
            text-align: center;
            margin: 10px 0;
        }
        .image-container img {
            width: 15%;
            height: auto;
            display: inline-block;
            margin: 0 5px;
        }
        .center-image {
            display: block;
            height: auto;
            margin: 10px auto;
            width: 95%;
        }
    </style>
  <h1>🍴 Feed your Poppin : Tutorial (3/3)🍴</h1>
          <p>
        Give it <strong><span style="color: orange;">10 popcal</span></strong>  to see your Murpie evolve!
    </p>  
            <img src="${evolve.src}" alt="take photo" class="center-image">
    <p>
Feed Popbites to your Poppin and watch it evolve into various forms. A small pet that transforms your eating habits into cherished memories 🤌
    </p>
             <div class="image-container">
  <img src="https://poppin-public-assets.s3.us-west-1.amazonaws.com/poppins/7/0/dict.png" alt="poppin1"/>
  <img src="https://poppin-public-assets.s3.us-west-1.amazonaws.com/popbites/2001.png" alt="popbites2"/>
  <img src="https://poppin-public-assets.s3.us-west-1.amazonaws.com/poppins/16/0/dict.png" alt="poppin2"/>
  <img src="https://poppin-public-assets.s3.us-west-1.amazonaws.com/popbites/3002.png" alt="popbites3"/>
  <img src="https://poppin-public-assets.s3.us-west-1.amazonaws.com/poppins/27/0/dict.png" alt="poppin5"/>
    </div>  
        
    </div>
 `
